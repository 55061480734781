import * as React from "react";
import { Link } from "gatsby";
import { css, cx } from "@emotion/css";

const container = css`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
`;
const item = css`
  display: inline-block;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 2;
  padding: 0 1em;
  text-decoration: none;
  color: #333;
  &:hover {
    background-color: #ddd;
    text-decoration: none;
  }
`;
const current = css`
  background: #bce;
`;
const nonact = css`
  pointer-events: none;
  opacity: 0.5;
`;

const PageBtn = ({ link, disable, isCurrent, children }) => {
  return (
    <Link
      className={cx(item, isCurrent ? current : "", disable ? nonact : "")}
      to={link}
    >
      {children}
      {disable}
    </Link>
  );
};

const Pagenation = ({ totalPageCount, nowPage, directory }) => {
  const range = (start, end) =>
    [...Array(end - start + 1)].map((_, i) => start + i);
  return (
    <div className={container}>
      <PageBtn
        link={
          nowPage - 1 > 1
            ? `/${directory}/page/${nowPage - 1}`
            : `/${directory}`
        }
        disable={nowPage - 1 <= 0}
      >
        prev
      </PageBtn>
      {range(1, totalPageCount).map((number, index) => {
        return (
          <PageBtn
            link={
              number === 1 ? `/${directory}` : `/${directory}/page/${number}`
            }
            key={index}
            isCurrent={nowPage === number}
          >
            {number}
          </PageBtn>
        );
      })}
      <PageBtn
        link={`/${directory}/page/${nowPage + 1}`}
        disable={nowPage + 1 > totalPageCount}
      >
        next
      </PageBtn>
    </div>
  );
};

export default Pagenation;
