import * as React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

const CategoryNave = () => {
  const query = useStaticQuery(graphql`
    query {
      allMicrocmsNewsCategory {
        edges {
          node {
            name
            newsCategoryId
            slug
          }
        }
      }
    }
  `);
  return (
    <ul>
      <li>
        <Link to={`/news`}>News一覧</Link>
      </li>
      {query.allMicrocmsNewsCategory.edges.map(({ node }) => {
        return (
          <li>
            <Link to={`/news/category/${node.slug}`}>{node.name}</Link>
          </li>
        );
      })}
    </ul>
  );
};

export default CategoryNave;
