import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import NewsItem from "../components/news-item";
import Pagenation from "../components/pagenation";
import CategoryNave from "../components/categoryNave";

const pageTitle = "News";

const News = ({ data, pageContext }) => {
  return (
    <Layout pageTitle={pageTitle}>
      <CategoryNave />
      {data.allMicrocmsNews.nodes.map((node) => {
        return <NewsItem postData={node} />;
      })}
      <Pagenation
        totalPageCount={pageContext.pageCount}
        nowPage={pageContext.nowPage}
        directory="news"
      />
    </Layout>
  );
};

export default News;

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMicrocmsNewsCategory {
      edges {
        node {
          name
          newsCategoryId
          slug
        }
      }
    }
    allMicrocmsNews(
      sort: { fields: publishedAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        title
        publishedAt(formatString: "YYYY.MM.DD")
        newsId
        category {
          newsCategoryId
          name
          slug
        }
      }
      totalCount
    }
  }
`;
